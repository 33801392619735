<template>
  <v-container fluid class="down-top-padding">
    <div id="userRolesPage" class="page">
      <div class="page__header ">
        <Toolbar
          :showFiltersPanel="showFiltersPanel"
          @showFiltersPanelChanged="handleShowFiltersPanelChanged"
          @toggleDialog="toggleDialog"
        />
      </div>
      <div class="page__body pa-4">
        <div class="mb-4" v-show="showFiltersPanel">
          <v-row class="my-0">
            <v-col>
              <Filters @filtersChanged="handleFiltersChanged" />
            </v-col>
          </v-row>
        </div>

        <v-dialog v-model="dialog" persistent scrollable max-width="1000px">
          <Form
            v-if="dialog"
            @formSubmit="handleFormSubmit"
            :toggleDialog="toggleDialog"
            :isSubmittingForm="isSubmittingForm"
            :selectedRole="selectedRole"
          />
        </v-dialog>
        <List
          :page="page"
          :items="items"
          :totalCount="totalCount"
          :isFetchingItems="isFetchingItems"
          :itemsPerPage="itemsPerPage"
          @editItem="handleEditItem"
          @deleteItem="handleDeleteItem"
          @optionsChanged="handleOptionsChanged"
        />
      </div>
      <ConfirmationPopup
        id="role-delete-modal"
        v-if="roleToDelete"
        title="Rolun silinməsi"
        :dialog="confirmationDialog"
        :loading="isDeletingRole"
        @confirm="handleDeleteConfirmation"
        @cancel="handleDeleteCancellation"
      >
        <span class="font-weight-bold">
          {{ roleToDelete.roleName }}
        </span>
        rolunu silmək istədiyinizə əminsiniz?
      </ConfirmationPopup>
    </div>
  </v-container>
</template>

<script>
import API from "@/services";
import ConfirmationPopup from "@/shared/Common/ConfirmationPopup.vue";
import bus from "../../../event-bus";
import List from "./components/List/index.vue";
import Form from "./components/Form/index.vue";
import Toolbar from "./components/Toolbar/index.vue";
import Filters from "./components/Toolbar/components/Filters.vue";

export default {
  components: {
    List,
    Form,
    Toolbar,
    ConfirmationPopup,
    Filters,
  },
  data() {
    return {
      dialog: false,
      confirmationDialog: false,
      selectedRole: null,
      roleToDelete: null,
      page: 1,
      isFetchingItems: false,
      isSubmittingForm: false,
      isDeletingRole: false,
      totalCount: 0,
      itemsPerPage: 10,
      items: [],
      filters: {},
      sortedBy: null,
      sortedDesc: null,
      showFiltersPanel: false,
    };
  },
  async created() {
    this.fetchRoles();
  },
  methods: {
    fetchRoles() {
      this.isFetchingItems = true;
      API.fetchRoles({
        limit: this.itemsPerPage,
        offset: (this.page - 1) * this.itemsPerPage,
        sortedBy: this.sortedBy,
        sortedDesc: this.sortedDesc,
        ...this.filters,
      })
        .then((response) => {
          this.items = response.items;
          this.totalCount = response.totalCount;
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Rolların siyahısını əldə etmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.isFetchingItems = false;
        });
    },
    handleFormSubmit(formData) {
      this.isSubmittingForm = true;
      const request = this.selectedRole
        ? API.updateRole(this.selectedRole.id, formData)
        : API.insertRole(formData);

      request
        .then(() => {
          this.toggleDialog();
          this.fetchRoles();
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Əməliyyatı aparmaq mümkün olmadı.",
          });
        })
        .finally(() => {
          this.isSubmittingForm = false;
        });
    },
    handleEditItem(item) {
      this.isFetchingItems = true;
      API.fetchRoleById(item.id)
        .then((response) => {
          this.selectedRole = response;
          this.dialog = true;
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Məlumatı əldə etmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.isFetchingItems = false;
        });
    },
    handleDeleteItem(item) {
      this.roleToDelete = item;
      this.confirmationDialog = true;
    },
    handleDeleteConfirmation() {
      this.isDeletingRole = true;
      API.deleteRole(this.roleToDelete.id)
        .then(() => {
          this.confirmationDialog = false;
          this.roleToDelete = null;
          this.fetchRoles();
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Rolu silmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.isDeletingRole = false;
        });
    },
    handleDeleteCancellation() {
      this.confirmationDialog = false;
      this.roleToDelete = null;
    },
    handleFiltersChanged(filters) {
      if (JSON.stringify(filters) !== JSON.stringify(this.filters)) {
        this.page = 1;
        this.filters = { ...filters };
        this.fetchRoles();
      }
    },
    toggleDialog() {
      this.dialog = !this.dialog;
      if (!this.dialog) {
        this.selectedRole = null;
      }
    },
    handleOptionsChanged(item) {
      if (
        this.sortedBy !== item.sortedBy ||
        this.sortedDesc !== item.sortedDesc ||
        this.page !== item.page ||
        this.itemsPerPage !== item.itemsPerPage
      ) {
        this.page = item.page;
        this.itemsPerPage = item.itemsPerPage;
        this.sortedBy = item.sortedBy;
        this.sortedDesc = item.sortedDesc;
        this.fetchRoles();
      }
    },
    handleShowFiltersPanelChanged(item) {
      this.showFiltersPanel = item;
    },
  },
};
</script>
